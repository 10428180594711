/* Add font-display swap */
@import url('https://fonts.googleapis.com/css2?family=Racing+Sans+One&family=Roboto:wght@100;300;400;500;700;900&family=Source+Sans+3:wght@200;300;400;700;800;900&display=swap');

/* ============ Base Style ========= */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

/* ====== Pre-define css ========= */
h1, h2, h3, h4, h5,h6 {
  font-family: 'Racing Sans One', sans-serif;
}
