.footer-bg {
  background-color: #343a40;
  color: #ffffff;
  font-family: 'Arial', sans-serif;
}

.footer-title {
  font-weight: bold;
  text-transform: uppercase;
  color: #ffc107;
  margin-bottom: 20px;
}

.footer-link {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: #ffc107;
  text-decoration: none;
}

.social-links i {
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.social-links a:hover i {
  color: #ffc107;
}

.social-names span {
  color: #ffc107;
  margin-right: 20px;
}

.footer-contact div {
  margin-bottom: 10px;
}

.footer-contact i {
  color: #ffc107;
  margin-right: 10px;
}

.footer-contact a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-contact a:hover {
  color: #ffc107;
}

.container hr {
  border-top: 1px solid #495057;
}

.mt-5, .my-5 {
  margin-top: 3rem !important;
}

.py-4, .py-5 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
