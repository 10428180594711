/* header.css */
.header {
  background-color: #ffffff;
  border-bottom: 1px solid #eaeaea;
}

.header_top {
  background: #ffcc01;
  color: #fff;
  padding: 10px 0;
}

.header_top .logo {
  max-height: 100px;
  height: auto;
  width: auto;
}

.header_top .quote_section {
  background-color: #ffcc01;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s;
  color: #4f0707;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.header_top .quote_section:hover {
  background-color: #ffc107;
  text-decoration: none;
}

.header_top .contact-info a {
  color: #343a40;
  font-weight: bold;
}

.header_top .contact-info a:hover {
  background-color: #ffc107
}

.mid-header {
  background-color: #343a40;
  color: #ffffff;
  padding: 0.5rem 0;
  overflow: hidden;
  position: relative;
}

.ticker-container {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.ticker-content {
  display: inline-block;
  animation: ticker 30s linear infinite;
}

@keyframes ticker {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.ticker-content span {
  display: inline-block;
  margin-right: 2rem;
  font-size: 1.2rem;
}

.ticker-content i {
  color: #ffc107;
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.header-pages {
  background-color: #ffcc01;
}

.header-pages a {
  color: black;
  transition: color 0.3s;
}

.header-pages a:hover {
  color: white;
}

.navbar-toggler {
  border: 2px solid #343a40; /* Bolder border */
  background-color: #343a40; /* Background color updated to match mid-header */
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.navbar-toggler:focus {
  outline: none;
}

.navbar-toggler .navbar-toggler-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff; /* Ensure the icon color stands out against the background */
  font-size: 1.5rem;
}

.text-light {
  color: #f8f9fa !important;
}

.text-dark {
  color: #343a40 !important;
}

@media (max-width: 920px) {
  .quote_section, .contact-info {
    display: none;
  }

  .navbar .navbar-nav .nav-link {
    text-align: center;
  }

  .navbar .navbar-nav .contact-info, .navbar .navbar-nav .quote_section {
    display: block;
    margin: 10px 0;
  }
}
