/* Font Import */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap');

:root {
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-style: normal; /* Ensures no text is italic globally */
}

/* Overlay background */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Popup container */
.popup-container {
    background: #ffffff; /* White background */
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    max-width: 50%;

    width: 100%; /* Adjusted for smaller screens */
    flex-direction: row;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    font-family: "Lato", sans-serif;
    font-style: normal; /* Ensure no italic styles */
}

/* Close button */
.popup-close {
    position: absolute;
    top: -5px;
    right: 10px;
    border: none;
    background: none;
    font-size: 24px;
    cursor: pointer;
    color: #e60000; /* Red close button */
    font-family: "Lato", sans-serif;
    font-weight: bolder;
    font-style: normal; /* No italic */
}

.popup-close:hover {
    color: #cc0000; /* Darker red on hover */
}

/* Content layout */
.popup-content {
    display: flex;
    flex-wrap: wrap; /* Enable wrapping for smaller screens */
    width: 100%;
    font-family: "Lato", sans-serif;
    font-style: normal; /* No italic */
}

.popup-image {
    max-width: 50%;
    object-fit: cover;
    width: 100%; /* Allow image to scale on mobile */
    height: auto;
}

/* Text section */
.popup-text {
    padding: 20px;
    flex: 1; /* Make text section flexible */
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: "Lato", sans-serif;
    font-style: normal; /* No italic */
}

.popup-title {
    font-size: 16px; /* Adjusted size */
    color: #e60000; /* Bright red title */
    margin-bottom: 10px;
    text-align: center; /* Center text for mobile */
    font-family: "Lato", sans-serif;
    font-weight: bold;
    font-style: normal; /* No italic */
}

.popup-description {
    font-size: 13px; /* Adjusted size */
    color: #333; /* Dark text for readability */
    margin-bottom: 10px;
    text-align: center; /* Center text for mobile */
    font-family: "Lato", sans-serif;
    font-weight: bold;
    font-style: normal; /* No italic */
}

/* Countdown notice board style */
.countdown {
    background: #fff5a3; /* Light yellow background */
    color: #333; /* Dark text for readability */
    padding: 10px 0; /* Add vertical padding only */
    border: 2px #e6b800; /* Dashed border */
    border-radius: 0; /* Remove border-radius to ensure edges are square */
    margin: 0; /* Remove any outer margins */
    width: calc(100% + 40px); /* Extend width to account for parent's padding */
    box-sizing: border-box; /* Include border and padding in the element's size */
    position: relative; /* Position it relative to its container */
    left: -20px; /* Offset to align with the parent container edges */
    text-align: center; /* Center-align text */
    font-size: 16px; /* Font size for better readability */
    font-family: "Lato", sans-serif;
    font-weight: bold;
}


/* Expired notice style */
.countdown p {
    margin: 0; /* Remove default margins */
}

/* Add hover effect (optional) */
.countdown:hover {
    background: #fff5a3; /* Slightly darker yellow on hover */
    border-color: #d4a200; /* Darker border on hover */
}

/* Button */
.popup-button {
    background: #ffd700; /* Gold button */
    color: #3f3e3e;
    border: none;
    margin-top: 20px;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px; /* Adjusted size */
    font-family: "Lato", sans-serif;
    font-weight: bold;
    align-self: center; /* Center button on mobile */
    font-style: normal; /* No italic */
}

.popup-button:hover {
    background: #e6b800; /* Darker gold on hover */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .popup-container{
        max-width: 600px;
        height: 580px;
        
    }
    .popup-overlay {
        display: flex; /* Ensure the popup is centered */
        align-items: center; /* Center popup vertically */
        justify-content: center; /* Center popup horizontally */
    }
    .popup-container {
        flex-direction: column; /* Stack image and text vertically */
        width: 95%; /* Adjust width for smaller screens */
    }

    .popup-image {
        max-width: 100%; /* Image spans full width on small screens */
        width: 100%;
    }

    .popup-text {
        text-align: center; /* Center-align text for better readability */
        padding: 15px;
    }

    .popup-title {
        font-size: 18px; /* Further reduced title size */
    }

    .popup-description {
        font-size: 14px; /* Adjusted description size */
    }

    .popup-button {
        font-size: 14px; /* Button size adjustment */
    }
}

@media (max-width: 480px) {

    .popup-overlay {
        display: flex; /* Keep the popup centered */
        justify-content: center;
        align-items: center;
    }

    .popup-container {
        max-width: 95%;
        height:auto;
        padding: 0;
        bottom: 100px;
    }

    .popup-title {
        font-size: 16px;
    }

    .popup-description {
        font-size: 13px;
    }

    .popup-button {
        font-size: 13px;
        padding: 8px 12px;
    }

    .countdown {
        font-size: 13px;
        padding: 6px;
    }
}

@media (max-width: 390px) {
    .popup-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.6); /* Slightly darker for smaller screens */
    }

    .popup-container {
        max-width: 100%;
        height: auto;
        bottom: 20px;
        
    }

    .popup-title {
        font-size: 14px;
        margin-bottom: 8px;
    }

    .popup-description {
        font-size: 12px;
        margin-bottom: 10px;
    }

    .popup-button {
        font-size: 12px;
        padding: 6px 10px;
    }

    .countdown {
        font-size: 12px;
        padding: 5px;
    }

    .popup-close {
        font-size: 20px;
    }
}

@media (min-width: 800px) and (max-width: 1024px) {
   

    .popup-overlay {
        display: flex; /* Centering for tablets */
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.5); /* Uniform overlay for tablets */
    }

    .popup-container {
        max-width: 40%; /* Restrict popup width to fit nicely on tablets */
        padding: 0; /* Add padding inside the popup */
        bottom: 300px;
    }

    .popup-image {
        max-width: 100%; /* Ensure the image scales properly */
        height: auto;
        object-fit: cover; /* Maintain aspect ratio of the image */
    }

    .popup-title {
        font-size: 15px; /* Increase font size for better readability */
        margin-bottom: 5px; /* Add spacing below the title */
    }

    .popup-description {
        font-size: 13px; /* Larger description text */
        margin-bottom: 10px; /* Add spacing below the description */
    }

    .popup-button {
        font-size: 13px; /* Larger button text */
        padding: 12px 20px; /* Adjust button padding */
    }

    .countdown {
        font-size: 15px; /* Slightly larger countdown text */
        padding: 10px;
    }

    .popup-close {
        font-size: 20px; /* Adjust close button size */
    }
}


@media (max-width: 240px) {

    
    .popup-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.7); /* Darker overlay for very small screens */
    }

    .popup-container {
        max-width: 90%; /* Ensure proper scaling */
        padding: 0; /* Reduce padding for smaller screens */
     
    }

    .popup-image {
        max-width: 100%; /* Scale the image to fit */
        height: auto;
        object-fit: cover; /* Ensure the image fits nicely */
    }

    .popup-title {
        font-size: 9px; /* Reduce font size for the title */
        margin-bottom: 1px;
    }

    .popup-description {
        font-size: 8px; /* Adjust font size for readability */
        margin-bottom: 8px;
    }

    .popup-button {
        font-size: 8px; /* Adjust button font size */
        padding: 18px 5px; /* Reduce button padding */
    }

    .countdown-content{
        font-size: 8px;
    }
    .countdown {
        font-size: 8px; /* Adjust font size for countdown */
        
    }

    .popup-close {
        top: 10px;
        font-size: 16px; /* Adjust close button size */
    }
}
